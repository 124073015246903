<template>
  <Navbar name="urgencies" redirect="maintenance.urgencies" />
</template>
<script>
/**
 * ==================================================================================
 * Maintenance Urgency Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
