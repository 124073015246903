<template>
  <div class="urgency-form">
    <v-form ref="form" class="urgency-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <label class="text-field-label">Urgency</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Name"
        v-model="form.label"
        :error-messages="form.$getError('label')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <div class="mt-4">
        <v-btn
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          width="100%"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Maintenance urgency Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    urgency: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        label: '',
      }),
    }
  },

  computed: {
    ...mapState({
      createdUrgency: (state) => state.urgency.urgencyDetails,
    }),

    header() {
      return this.isUpdate ? 'Urgency Information' : 'Create Urgency'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateUrgency : this.createUrgency
    },

    isUpdate() {
      return !!this.urgency
    },
  },

  watch: {
    urgency(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createUrgency: 'urgency/createUrgency',
      updateUrgency: 'urgency/updateUrgency',
    }),

    initForm() {
      if (this.urgency) {
        this.form.label = this.urgency.label
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Urgency details successfully updated!')
          } else {
            this.showSnackbar('Urgency successfully created!')
            this.$router.push({
              name: 'maintenance.urgencies',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.urgency.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.urgency-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
